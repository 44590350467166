export default {
    data() {
      return {
        bLoading: false,
        screenWidth: 0,
      };
    },
    beforeMount() {
      this.texts = FILE.ordersTexts[this.selectLanguage];
    },
    created() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize);
      this.close();
    },
    methods: {
      handleResize() {
        if (window.innerWidth >= 960) {
          this.screenWidth = 45;
        } else {
          this.screenWidth = 100;
        }
      },
      close() {
        this.$store.commit("setDialogChangePaymentMethod", false);
      },
      payMethodCharged() {
        this.bLoading = true;
  
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };
        const payload = {
          bPaid: true,
        };
        DB.patch(
          `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}/paid`,
          payload,
          config
        )
          .then((response) => {
            this.bLoading = false;
            this.close();
            this.mixSuccess(response.data.message);
  
            this.$store.commit("refresher", true);
          })
          .catch((error) => {
            this.bLoading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      },
    },
    watch: {
      dialogChangePaymentMethod() {
        if (this.dialogChangePaymentMethod) {
          this.texts = FILE.ordersTexts[this.selectLanguage];
        }
      },
      selectLanguage: function() {
        if (this.selectLanguage) {
          this.texts = FILE.ordersTexts[this.selectLanguage];
        }
      },
    },
    computed: {
      dialogChangePaymentMethodActive() {
        return this.$store.state.dialogChangePaymentMethodActive;
      },
      selectLanguage() {
        return this.$store.state.language;
      },
    },
  };
  